/* eslint-disable import/no-anonymous-default-export */
export default {
  baseUri: process.env.REACT_APP_BASE_URL || 'https://api-partner.stage.sputnik.systems/api/v1',
  redirectUri: process.env.REACT_APP_REDIRECT_URL || 'https://api-partner.stage.sputnik.systems',
  ssoUri: 'https://sso.sputnik.systems',
  intercomId: 'PartnerUserWeb',
  userUri: 'https://account.sputnik.systems',
  app: 'Партнёр',
  brand: 'Спутник',
}
